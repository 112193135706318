import { NgModule } from '@angular/core';
import { ConferenceService } from './providers/conference.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { ConferenceEffects } from './store/conference.effect';
import { conferencesFeatureKey, reducer as conferenceReducer } from './store/conference.reducer';
import { TokenService } from './providers/token.service';

@NgModule({
    imports: [
        StoreModule.forFeature(conferencesFeatureKey, conferenceReducer),
        EffectsModule.forFeature([ConferenceEffects]),
    ],

    providers: [
        ConferenceService, TokenService
    ]
})
export class ConferenceV2Module {
}
