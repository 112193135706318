import { Observable } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { PaymentInfoService } from '../provider/payment-info.service';
import { ProfileType } from '../../../modules/profile/store/profile.entity';
import {
    PROFILE_DATA_OF_CURRENT_RECEIVED_SUCCESSFUL,
    ProfileDataReceivedSuccessfulAction
} from '../../../modules/profile/store/profile.action';

@Injectable()
export class PaymentInfoEffects {
    constructor(private actions$: Actions,
                private paymentInfoService: PaymentInfoService) {
    }

     requestPaymentInfoData$: Observable<Action[] | Action> = createEffect(() => this.actions$.pipe(
        ofType(PROFILE_DATA_OF_CURRENT_RECEIVED_SUCCESSFUL),
        filter((action: ProfileDataReceivedSuccessfulAction) => action.payload.type === ProfileType.DOCTOR),
        tap(() => this.paymentInfoService.readPaymentInfo().pipe(take(1)).subscribe())
    ), {dispatch: false});
}
