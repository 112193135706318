
import {map} from 'rxjs/operators';
import { Conference, EntityStateConference } from '../store/conference.entity';
import { Store } from '@ngrx/store';
import { selectAllConferences } from '../store/conference.selector';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class TokenService {

    constructor(private store: Store<EntityStateConference>) {
    }

    getConferenceTokens(): Observable<Conference[]> {
        return this.store.select(selectAllConferences).pipe(map(c => c));
    }

}
