import {
    PreInitStateInterface,
    PreInitDataProcessTermAcceptedState,
    PreInitCompletedState
} from './pre-init.state';
import {
    PRE_INIT_DATA_PROCESS_TERM_ACCEPTED,
    PRE_INIT_COMPLETED
} from './pre-init.action';

const preInitStartState: PreInitStateInterface = {
    acceptedDataProcessTerm: false,
    completed: false
};

export function preInitReducer(state: PreInitStateInterface = preInitStartState, action): PreInitStateInterface {

    switch (action.type) {
        case PRE_INIT_DATA_PROCESS_TERM_ACCEPTED:
            return new PreInitDataProcessTermAcceptedState();
        case PRE_INIT_COMPLETED:
            return new PreInitCompletedState();
        default:
            return state;
    }
}
