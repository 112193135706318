import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { APP_CONFIG } from './app/providers/app-config/app-config.types';

if (environment.production) {
    enableProdMode();
}

// ErrorCatch is handled in the LoggingClientSideModule Service
fetch(environment.appConfigEndpoint).then(async res => {
    const appConfig = await res.json();
    platformBrowserDynamic([
        {provide: APP_CONFIG, useValue: appConfig}
    ]).bootstrapModule(AppModule)
        .catch(err => console.log(err));
});
