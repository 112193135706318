import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { ProfileService } from '../../profile/providers/profile.service';
import { AppointmentRequest } from '../../appointment-request/store/appointment-request.entity';
import { EmailTypeAppointmentRequestAccept } from '../types/appointment-request-accept';
import { environment } from '../../../../environments/environment';
import { EmailTypeAppointmentRequestReject } from '../types/appointment-request-reject';
import { AppState } from '../../../providers/store/app.state';
import { DATE_TIME_FORMAT_EXPORT, TimeHelperService } from '../../utils/time-helpers/time-helper.service';
import { EmailTypeOneTimeAppointment } from '../types/one-time-appointment';
import { getGenderNameById } from '../../utils/genders';
import { CreateTanAppointmentDTO } from '../../appointment/providers/create-tan.types';

@Injectable()
export class EmailService {

    constructor(
        private http: HttpClient,
        private profileService: ProfileService,
        private store: Store<AppState>) {
    }

    public prepareAppointmentRequestAcceptPayload(appointmentRequest: AppointmentRequest): Observable<EmailTypeAppointmentRequestAccept> {
        return this.profileService.getCurrentProfileDataObserver().pipe(map(response => {

            const startDateTime = TimeHelperService
                .getMomentDateTime(appointmentRequest.appointmentStartDateTime)
                .format(DATE_TIME_FORMAT_EXPORT);

            const payload: EmailTypeAppointmentRequestAccept = {
                type: 'bookable-appointment-accepted',
                data: {
                    doctor_salutation: response.salutation,
                    doctor_title: response.title,
                    doctor_first_name: response.firstName,
                    doctor_last_name: response.lastName,
                    doctor_telephone_number: response.telephoneNumber,
                    doctor_public_profile: response.publicProfileUrl,
                    branding: response.branding,
                    patient_email: appointmentRequest.email,
                    patient_salutation: getGenderNameById(appointmentRequest.salutation.trim()),
                    patient_first_name: appointmentRequest.firstName,
                    patient_last_name: appointmentRequest.lastName,
                    appointment_tan: appointmentRequest.tan,
                    appointment_confirmation_comment: appointmentRequest.confirmationComment,
                    appointment_start_date_time: startDateTime,
                }
            };

            return payload;
        }));
    }

    public sendAppointmentRequestAcceptEmail(payload: EmailTypeAppointmentRequestAccept): Observable<any> {
        return this.http.post(environment.emailEndPoint, payload);
    }

    public prepareAppointmentRequestRejectPayload(appointmentRequest: AppointmentRequest): Observable<EmailTypeAppointmentRequestReject> {
        return this.profileService.getCurrentProfileDataObserver().pipe(map(response => {

            const startDateTime = TimeHelperService
                .getMomentDateTime(appointmentRequest.appointmentStartDateTime)
                .format(DATE_TIME_FORMAT_EXPORT);

            const payload: EmailTypeAppointmentRequestReject = {
                type: 'bookable-appointment-rejected',
                data: {
                    doctor_salutation: response.salutation,
                    doctor_title: response.title,
                    doctor_first_name: response.firstName,
                    doctor_last_name: response.lastName,
                    doctor_telephone_number: response.telephoneNumber,
                    doctor_public_profile: response.publicProfileUrl,
                    branding: response.branding,
                    patient_email: appointmentRequest.email,
                    patient_salutation: getGenderNameById(appointmentRequest.salutation.trim()),
                    patient_first_name: appointmentRequest.firstName,
                    patient_last_name: appointmentRequest.lastName,
                    appointment_confirmation_comment: appointmentRequest.confirmationComment,
                    appointment_start_date_time: startDateTime,
                }
            };

            return payload;
        }));
    }

    public sendAppointmentRequestRejectEmail(payload: EmailTypeAppointmentRequestReject): Observable<any> {
        return this.http.post(environment.emailEndPoint, payload);
    }

    public prepareOnetimeAppointmentPayload(dto: CreateTanAppointmentDTO): Observable<EmailTypeOneTimeAppointment> {
        return this.profileService.getCurrentProfileDataObserver().pipe(map(response => {
            const startDateTime = TimeHelperService
                .getMomentDateTime(dto.appointment.startDateTime)
                .format(DATE_TIME_FORMAT_EXPORT);

            const payload: EmailTypeOneTimeAppointment = {
                type: 'one-time-appointment',
                data: {
                    doctor_salutation: response.salutation,
                    doctor_title: response.title,
                    doctor_first_name: response.firstName,
                    doctor_last_name: response.lastName,
                    doctor_telephone_number: response.telephoneNumber,
                    doctor_email: response.email,
                    doctor_public_profile: response.publicProfileUrl,
                    branding: response.branding,
                    patient_email: dto.profile.email,
                    patient_salutation: getGenderNameById(dto.profile.salutation.trim()),
                    patient_first_name: dto.profile.firstName,
                    patient_last_name: dto.profile.lastName,
                    appointment_tan: dto.appointment.tan,
                    appointment_tan_message: dto.getEmailMessage(),
                    appointment_start_date_time: startDateTime,
                }
            };
            return payload;
        }));
    }

    public sendOneTimeAppointmentEmail(payload: EmailTypeOneTimeAppointment): Observable<any> {
        return this.http.post(environment.emailEndPointOneTimeAppointment, payload);
    }

}
