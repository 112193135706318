import { Action } from '@ngrx/store';

export const PRE_INIT_DATA_PROCESS_TERM_ACCEPTED = 'PRE_INIT_DATA_PROCESS_TERM_ACCEPTED';

export class PreInitDataProcessTermAcceptedAction implements Action {
    public type = PRE_INIT_DATA_PROCESS_TERM_ACCEPTED;
}

export const PRE_INIT_REQUIRED_DATA_PROCESS_TERM_ACCEPTED = 'PRE_INIT_REQUIRED_DATA_PROCESS_TERM_ACCEPTED';

export class PreInitRequiredDataProcessTermAcceptedAction implements Action {
    public type = PRE_INIT_REQUIRED_DATA_PROCESS_TERM_ACCEPTED;
}

export const PRE_INIT_COMPLETED = 'PRE_INIT_COMPLETED';

export class PreInitCompletedAction implements Action {
    public type = PRE_INIT_COMPLETED;
}

export const PRE_INIT_START_CHECK = 'PRE_INIT_START_CHECK';

export class PreInitStartCheckAction implements Action {
    public type = PRE_INIT_START_CHECK;
}
