import { ErrorHandler, ModuleWithProviders, NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { GlobalErrorHandlerService } from './providers/global-error-handler/global-error-handler.service';


@NgModule({ imports: [], providers: [provideHttpClient(withInterceptorsFromDi())] })
export class CustomErrorHandlerModule {
    static forRoot(isProductionMode: boolean): ModuleWithProviders<CustomErrorHandlerModule> {
        return {
            ngModule: CustomErrorHandlerModule,
            providers: [
                {
                    provide: ErrorHandler,
                    useClass: GlobalErrorHandlerService
                }
            ]
        };
    }
}
