import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpecialityService } from './provider/speciality.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [SpecialityService],
})
export class SpecialityModule {
}
