import { filter } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { CustomTranslateService } from './providers/translate/services/custom-translate.service';
import { AppStateService } from './providers/store/app-state.service';
import { BrandingService } from './modules/branding/providers/branding.service';
import { Title } from '@angular/platform-browser';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
    constructor(private customTranslateService: CustomTranslateService,
                private appState: AppStateService,
                private titleService: Title,
                private branding: BrandingService) {
        customTranslateService.initTranslate();
        branding.initBranding();
        branding.getBrandingSettingsFromStore().pipe(filter(e => e !== null && e !== undefined)).subscribe(
            (res) => {
                if (res.title) {
                    this.titleService.setTitle(res.title);
                }
            },
            (err) => console.log(err)
        );
    }

    ngOnInit(): void {
        this.appState.initState();
    }
}
