import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PayOutService } from './provider/pay-out.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [PayOutService],
})
export class PaymentPayOutModule {
}
