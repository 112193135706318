import { NgModule } from '@angular/core';
import { ConferenceService } from './providers/conference.service';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { conferenceReducer } from './store/conference.reducer';
import { ConferenceEffects } from './store/conference.effect';
import { TokenService } from './providers/token.service';


@NgModule({
    imports: [
        StoreModule.forFeature('conference', conferenceReducer),
        EffectsModule.forFeature([ConferenceEffects]),
    ],

    providers: [
        ConferenceService,
        TokenService
    ]
})
export class ConferenceModule {
}
