import { Conference, conferenceAdapter, EntityStateConference, initialConfenceListState } from './conference.entity';
import {
    CONFERENCE_ACTIVATE,
    ConferenceAction,
    ConferenceActivateAction,
    CONFERENCE_ADD,
    ConferenceAddAction,
    CONFERENCE_LIST_ADD_SUCCESS,
    ConferenceListAddSuccessAction,
    CONFERENCE_REMOVE_ALL
} from './conference.action';

export function conferenceReducer(state: EntityStateConference = initialConfenceListState,
                                  action: ConferenceAction): EntityStateConference {
    switch (action.type) {
        case CONFERENCE_ACTIVATE: {
            const activateAction = <ConferenceActivateAction>(action);
            return {...state, activeId: activateAction.payload.id};
        }
        case CONFERENCE_LIST_ADD_SUCCESS:
            const loadAction = <ConferenceListAddSuccessAction>(action);
            return conferenceAdapter.setAll(loadAction.payload, state);

        case CONFERENCE_ADD: {
            const conference: Conference = (<ConferenceAddAction>action).payload;
            const newState: EntityStateConference = conferenceAdapter.addOne(conference, state);
            return newState;
        }
        case CONFERENCE_REMOVE_ALL: {
            return initialConfenceListState;
        }
        default: {
            return state;
        }
    }
}
