import { NgModule } from '@angular/core';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { PollingClientService } from './polling-client.service';

@NgModule({ imports: [], providers: [
        PollingClientService,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class HttpModule {
}
