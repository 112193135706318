import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { PreInitEffects } from './store/pre-init.effect';
import { PreInitConditionService } from './providers/pre-init-condition.service';
import { StoreModule } from '@ngrx/store';
import { preInitReducer } from './store/pre-init.reducer';
import { InitPreConditionComponent } from './init-pre-condition.component';


@NgModule({
    imports: [
        CommonModule,
        EffectsModule.forFeature([PreInitEffects]),
        StoreModule.forFeature('preInit', preInitReducer),
    ],
    declarations: [
        InitPreConditionComponent,
    ],
    providers: [
        PreInitConditionService
    ],
    exports: [],
})
export class PreInitConditionModule {
}
