import { AppState as RootAppState } from '../../../providers/store/app.state';

export interface PreInitStateInterface {
    acceptedDataProcessTerm: boolean;
    completed: boolean;
    error?: string;
}

export class PreInitCompletedState implements PreInitStateInterface {
    acceptedDataProcessTerm = true;
    completed = true;

    constructor() {
    }
}

export class PreInitDataProcessTermAcceptedState implements PreInitStateInterface {
    acceptedDataProcessTerm = true;
    completed = false;
}

export class PreInitFailedState implements PreInitStateInterface {
    acceptedDataProcessTerm = false;
    completed = false;

    constructor(public error: string) {
    }
}

export interface AppState extends RootAppState {
    preInit: PreInitStateInterface;
}
