import { NgModule } from '@angular/core';
import { LoadingSpinnerComponent } from './loading-spinner.component';
import { CommonModule } from '@angular/common';
import { LoadingSpinnerService } from './loading-spinner.service';

@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [
        LoadingSpinnerComponent
    ],
    declarations: [
        LoadingSpinnerComponent,
    ],
    providers: [
        LoadingSpinnerService
    ]
})
export class LoadingSpinnerModule {
}
