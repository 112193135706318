import { mergeMap, tap } from 'rxjs/operators';
import { Action } from '@ngrx/store';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
    PROFILE_ACCEPT_DATA_PROCESS_SUCESS,
    PROFILE_DATA_OF_CURRENT_RECEIVED_SUCCESSFUL,
    ProfileAcceptDataProcessSuccessAction,
    ProfileDataReceivedSuccessfulAction
} from '../../profile/store/profile.action';
import {
    DASHBOARD_ROUTE,
    LEGAL_DATA_PROCESS_TERMS,
    APPOINTMENT_OVERVIEW_MULTIUSER_UPCOMING,
    WAITINGROOM_MULTIUSER_ROUTE
} from '../../../routes';
import { ProfileType } from '../../profile/store/profile.entity';
import {
    PRE_INIT_REQUIRED_DATA_PROCESS_TERM_ACCEPTED,
    PreInitCompletedAction,
    PreInitDataProcessTermAcceptedAction,
    PreInitRequiredDataProcessTermAcceptedAction
} from './pre-init.action';
import { Observable, of } from 'rxjs';
import { RouterHelperService } from '../../utils/router-helper/router-helper.service';
import { RestrictedFeatureRouteMap } from "../../../entities/institution/institution.model";

@Injectable()
export class PreInitEffects {
    constructor(
        private actions$: Actions,
        private router: RouterHelperService) {
    }

    // PRE_INIT_START_CHECK: At the moment is not needed to listen on

     checkForDataProcessTerm$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(PROFILE_DATA_OF_CURRENT_RECEIVED_SUCCESSFUL),
        mergeMap((action: ProfileDataReceivedSuccessfulAction) => {
            if (action.payload.type === ProfileType.PATIENT && action.payload.dataProcessTerm === false) {
                return of(new PreInitRequiredDataProcessTermAcceptedAction());
            } else {
                this.navigateOnLogin(action);
                return of(new PreInitCompletedAction());
            }
        })));

     markDataProcessTermAccepted$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(PROFILE_ACCEPT_DATA_PROCESS_SUCESS),
        mergeMap((action: ProfileAcceptDataProcessSuccessAction) => {
            const actions: Action[] = [
                new PreInitDataProcessTermAcceptedAction(),
                new PreInitCompletedAction(),
            ];

            return actions;
        })));

     doRedirectToAcceptDataProcessTerm$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(PRE_INIT_REQUIRED_DATA_PROCESS_TERM_ACCEPTED),
        tap(action => {
            this.router.navigate([LEGAL_DATA_PROCESS_TERMS]);
        })), {dispatch: false});

    private navigateOnLogin(action: ProfileDataReceivedSuccessfulAction): void {
        const institutionRoutes = RestrictedFeatureRouteMap[action.payload.institution];
        if (institutionRoutes && !institutionRoutes.hasOwnProperty(DASHBOARD_ROUTE)) {
            action.payload.type === ProfileType.PATIENT ?
                this.router.navigate([WAITINGROOM_MULTIUSER_ROUTE]) :
                this.router.navigate([APPOINTMENT_OVERVIEW_MULTIUSER_UPCOMING]);
        } else {
            this.router.navigate([DASHBOARD_ROUTE]);
        }
    }
}
