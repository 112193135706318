import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { EmailEffects } from './store/email.effect';
import { EmailService } from './providers/email.service';

@NgModule({
    imports: [
        CommonModule,
        EffectsModule.forFeature([EmailEffects]),
    ],
    providers: [
        EmailService
    ],
    exports: [],
})
export class EmailModule {
}
