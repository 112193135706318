import {Observable, of} from 'rxjs';

import {catchError, map, mapTo, switchMap} from 'rxjs/operators';
import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {ConferenceService} from '../providers/conference.service';
import {
    APPOINTMENTS_INITIAL_RECEIVED_SUCCESSFUL,
    AppointmentsInitialReceivedSuccessfulAction,
} from '../../appointment/store/one-time-appointment/appointment.action';
import {Appointment} from '../../appointment/store/one-time-appointment/appointment.entity';
import {Conference} from './conference.entity';
import {Action} from '@ngrx/store';
import {
    CONFERENCE_ADD,
    CONFERENCE_LIST_ADD_SUCCESS,
    ConferenceFailAction,
    ConferenceListAddSuccessAction,
    ConferenceListUpdateSuccessAction,
    ConferenceRemoveAllAction,
} from './conference.action';
import {APP_CHANGE_TO_ANONYMOUS_STATE} from '../../../providers/store/app.action';

@Injectable()
export class ConferenceEffects {
    constructor(private actions$: Actions,
                private conferenceService: ConferenceService) {
    }

     doRequestConferences$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType('TEMPORARY_DO_NOTHING'),
        // ofType(APPOINTMENTS_INITIAL_RECEIVED_SUCCESSFUL),
        map((action: AppointmentsInitialReceivedSuccessfulAction) => action.payload),
        switchMap((appointments: Appointment[]) => this.conferenceService
                .getConferenceList(appointments).pipe(
                    map((conferenceList: Conference[]) => new ConferenceListAddSuccessAction(conferenceList)),
                    catchError((err) => of(new ConferenceFailAction(err))),))));

     doRefreshConferences$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType('TEMPORARY_DO_NOTHING'),
        // ofType(CONFERENCE_ADD, CONFERENCE_LIST_ADD_SUCCESS),
        switchMap(() => this.conferenceService
                .getConferenceListFromStore().pipe(
                    map((conferenceList: Conference[]) => new ConferenceListUpdateSuccessAction(conferenceList)),
                    catchError((err) => of(new ConferenceFailAction(err))),))));

     doRemoveAllConferences$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType('TEMPORARY_DO_NOTHING'),
        // ofType(APP_CHANGE_TO_ANONYMOUS_STATE),
        mapTo(new ConferenceRemoveAllAction())));
}
