import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GeneralMessageBusService } from './providers/general-message-bus.service';


@NgModule({
    imports: [
        CommonModule,
    ],
    exports: [],
    declarations: [],
    providers: [GeneralMessageBusService]

})
export class MessageBusModule {
}
