import { Component } from '@angular/core';
import { LoadingSpinnerService } from './loading-spinner.service';
import { of } from 'rxjs';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent {

    public isActive$ = of(true);

    constructor(private spinnerService: LoadingSpinnerService) {
        this.isActive$ = this.spinnerService.spinnerControl$;
    }
}
