import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthStateProvider } from '../../modules/auth/auth-state.provider';
import {
    AppInitAnonymousStateAction,
    AppReloadedAndUserAlreadyLoggedInAction
} from './app.action';

@Injectable()
export class AppStateService {

    constructor(private store: Store<any>, private authState: AuthStateProvider) {
    }

    public initState() {
        this.authState.isAuthenticated().subscribe((authenticated: boolean) => {
            if (authenticated) {
                this.store.dispatch(new AppReloadedAndUserAlreadyLoggedInAction());
            } else {
                this.store.dispatch(new AppInitAnonymousStateAction());
            }
        }).unsubscribe();
    }
}
