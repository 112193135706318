import { mapTo, mergeMap, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../../modules/auth/store/login/login.action';
import { Action } from '@ngrx/store';
import {
    APP_RELOADED_AND_USER_ALREADY_LOGGED_IN,
    AppChangeToAnonymousStateAction,
    AppChangeToAuthenticatedStateAction,
    AppInitAuthenticatedStateAction
} from './app.action';
import { PRE_INIT_COMPLETED, PreInitStartCheckAction } from '../../modules/pre-init-condition/store/pre-init.action';
import { ProfileLoadCurrentUserProfileFromBackendAction } from '../../modules/profile/store/profile.action';
import { Observable, of } from 'rxjs';
@Injectable()
export class RootEffects {
    constructor(private actions$: Actions) {
    }

     doStartTheAuthenticateProcessAfterLogin$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(LOGIN_SUCCESS, APP_RELOADED_AND_USER_ALREADY_LOGGED_IN),
        mergeMap(() => [
            new AppInitAuthenticatedStateAction(),
            new PreInitStartCheckAction(),
            new ProfileLoadCurrentUserProfileFromBackendAction()
        ])));

     doChangeToIsAuthenticated$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(PRE_INIT_COMPLETED),
        mapTo(new AppChangeToAuthenticatedStateAction())));

     doChangeToAnonymousAfterLogout$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(LOGOUT_SUCCESS),
        switchMap(() => of(new AppChangeToAnonymousStateAction()))));
}
