import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import * as paymentInfo from './store/payment-info.reducer';
import { PaymentInfoService } from './provider/payment-info.service';
import { PaymentInfoStoreService } from './provider/payment-info-store.service';
import { EffectsModule } from '@ngrx/effects';
import { PaymentInfoEffects } from './store/payment-info.effects';
import { PaymentInfoPollingService } from './provider/payment-info-polling.service';
import { HttpModule } from '../../http/http.module';

@NgModule({
    imports: [
        CommonModule,
        HttpModule,
        StoreModule.forFeature('payment-info', paymentInfo.reducer),
        EffectsModule.forFeature([PaymentInfoEffects]),
    ],
    declarations: [],
    providers: [PaymentInfoService, PaymentInfoStoreService, PaymentInfoPollingService],
})
export class PaymentInfoModule {
}
