import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SurveyService } from './provider/survey.service';

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [SurveyService],
})
export class SurveyModule {
}
