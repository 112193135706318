import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LogAppointmentService } from './providers/log-appointment.service';

@NgModule({
    imports: [
        CommonModule,
    ],
    providers: [
        LogAppointmentService
    ]
})
export class LogAppointmentModule {
}
