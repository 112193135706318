import { BrowserModule } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth-guard.service';
import { PageNotFoundComponent } from './page-not-found.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

const loginRoutes: Routes = [
    // { path: 'login', component: LogoutComponent }
];

@NgModule({
    declarations: [
        PageNotFoundComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(loginRoutes),
        TranslateModule.forChild()
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AuthGuard,
        Store
    ]
})
export class PageNotFoundModule { }
