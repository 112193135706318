import { Observable, zip } from 'rxjs';

import { filter, map, mapTo, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ConferenceService } from '../providers/conference.service';
import {
    APPOINTMENTS_INITIAL_RECEIVED_SUCCESSFUL,
    AppointmentsInitialReceivedSuccessfulAction,
} from '../../appointment/store/one-time-appointment/appointment.action';
import { Action } from '@ngrx/store';

import { APP_CHANGE_TO_ANONYMOUS_STATE } from '../../../providers/store/app.action';
import { ClearConferences, UpsertConferences } from './conference.actions';
import { Conference } from './conference.model';
import { Appointment } from '../../appointment/store/one-time-appointment/appointment.entity';
import { TimeHelperService } from '../../utils/time-helpers/time-helper.service';
import { multiUserAppointmentsFetchSuccessAction } from '../../appointment/store/appointment/multi-user-appointment.action';
import { MultiUserAppointment } from '../../appointment/store/appointment/multi-user-appointment.entity';

@Injectable()
export class ConferenceEffects {
    constructor(private actions$: Actions,
                private conferenceService: ConferenceService) {
    }

     doRequestConferences$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APPOINTMENTS_INITIAL_RECEIVED_SUCCESSFUL),
        map((action: AppointmentsInitialReceivedSuccessfulAction) =>
            action.payload.filter((appointment: Appointment) => this.isUpcomingAppointment(appointment))
        ),
        filter((appointments: Appointment[]) => appointments.length !== 0),
        map((appointments: Appointment[]) => this.mapAppointmentsToConferencesId(appointments)),
        switchMap((conferencesId: number[]) => zip(...this.mapConferencesIdToConferencesObservable(conferencesId))),
        map((conferences: Conference[]) => new UpsertConferences({conferences}))
    ));

     doRemoveAllConferences$: Observable<Action> = createEffect(() => this.actions$.pipe(
        ofType(APP_CHANGE_TO_ANONYMOUS_STATE),
        mapTo(new ClearConferences())));

    doRequestConferencesMUC$ = createEffect(() => this.actions$.pipe(
        ofType(multiUserAppointmentsFetchSuccessAction),
        map((action) => action.items),
        filter((appointments: MultiUserAppointment[]) => appointments.length !== 0),
        map((appointments: MultiUserAppointment[]) => this.mapAppointmentsToConferencesId(appointments)),
        switchMap((conferencesId: number[]) => zip(...this.mapConferencesIdToConferencesObservable(conferencesId))),
        map((conferences: Conference[]) => new UpsertConferences({conferences}))
        )
    );

    private isUpcomingAppointment(appointment: Appointment): boolean {
        return (new Date(appointment.startDateTime) > new Date()) || TimeHelperService.isToday(appointment.startDateTime);
    }

    private mapAppointmentsToConferencesId(appointments: Appointment[]): number[] {
        return appointments.map(appointment => appointment.conferenceId);
    }

    private mapConferencesIdToConferencesObservable(conferencesId: number[]): Observable<Conference>[] {
        return conferencesId.map((conferenceId: number) => this.conferenceService.getConference(conferenceId));
    }
}
